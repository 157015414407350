import React from "react";
import styled from "styled-components";

const Content = ({ children }) => (
    <ContentWrapper>
        {children}
    </ContentWrapper>
);

const ContentWrapper = styled.div`
  max-width: 1136px;
  width: 100%;
  margin: 0 auto;
`;

export default Content;