import React from "react";
import styled from "styled-components";
import Content from "./content";
import { useIntl, Link } from "gatsby-plugin-intl"

import facebook from "../images/social/facebook.svg";
import linkedin from "../images/social/linkedin.svg";

const Footer = (props) => {
    const intl = useIntl();
    const {showFooter, showContact} = props;
    return (
        showFooter &&
        <FooterWrapper>
            <Content>
                {showContact &&
                    <ContactButton>
                        <Link to="/contact" className="btn btn-light animated fade">{intl.formatMessage({id:"contactButton"})} <span role="img" aria-label="feeling-cool">😎</span></Link>
                    </ContactButton>
                }
                <FooterContent>
                    <FooterSocialContent>
                        <a href="https://www.facebook.com/qobaltmx">
                            <img className="social--icon" src={facebook} alt="Qobalt Technologies Facebook" />
                        </a>
                        <a href="https://www.linkedin.com/company/qobalt-technologies">
                            <img className="social--icon" src={linkedin} alt="Qobalt Technologies LinkedIn"/>
                        </a>
                    </FooterSocialContent>
                    <FooterContactContent>
                        <div>
                            © {(new Date()).getFullYear()} Qobalt Technologies
                        </div>
                        <div className="address">
                            Mérida, Yucatán, México
                        </div>
                        <a href="mailto:hello@qobalt.mx" target="_blank" rel="noopener noreferrer"  className="email">
                            hello@qobalt.mx
                        </a>
                    </FooterContactContent>
                </FooterContent>
            </Content>
        </FooterWrapper>
    );
};

const FooterWrapper = styled.div`
  width: 100%;
  color: #f5f5f1;
  font-size: 14px;
  background-color: rgb(10, 18, 38);
  min-height: 80px;
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  padding-top: 20px;
  .email {
    margin-left: 15px;
    text-decoration: none;
    color: #f5f5f1; 
  }
  .address {
    margin-left: 15px;
  }
  .social--icon {
    max-width: 32px;
    margin-right: 22px;
    margin-bottom: 20px;
  }
  @media(max-width: 600px) {
    font-size: 11px;    
  }
`;
const FooterContactContent = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
`;
const FooterSocialContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const FooterContent = styled.div`
  
`;
const ContactButton = styled.div`
    padding-top: calc(2.125rem + 8.33333vw);
    padding-bottom: calc(2.125rem + 8.33333vw);
    text-align: center;
    & a.btn {
        display: inline-block;
        margin-right: auto;
        margin-left: auto;
        font-size: 2.5rem;
    }
  @media(max-width: 768px) {
    & a.btn {
      font-size: 1.8125rem !important;
    }
  }
`;

Footer.defaultProps = {
    showFooter: true,
    showContact: true,
};

export default Footer;