import React from "react";
import styled from "styled-components";
import Layout from "../layout";
import {useIntl} from "gatsby-plugin-intl";

import worldDots from "../../images/dot-map-background.webp";

const Term = (props) => {
    const intl = useIntl();
    const {title, description, benefits} = props;
    return (
        <Layout>
            <TermWrapper style={{backgroundImage:`url(${worldDots})`}}>
                <section className="section">
                    <div className="container-fluid">
                        <div className="content content--text">
                            <h1 className="section__title">→ {title}</h1>
                        </div>
                    </div>
                </section>
                <section className="section">
                    <div className="container-fluid">
                        <div className="content content--text">
                            <h2 className="section__title">{intl.formatMessage({id:"whatIsIt"})}</h2>
                            <p className="section--description">
                                <span className="description--info">
                                    {description}
                                </span>
                            </p>
                        </div>
                    </div>
                </section>
                <section className="section">
                    <div className="container-fluid">
                        <div className="content content--text">
                            <h2 className="section__title">{intl.formatMessage({id:"howCanYouBenefitFromIt"})}</h2>
                            <p className="section--description">
                                <span className="description--info">
                                    {benefits}
                                </span>
                            </p>
                        </div>
                    </div>
                </section>
            </TermWrapper>
        </Layout>
    )
};

const TermWrapper = styled.div`
  background: #ffffff no-repeat center;
  background-size: contain;
  @media(max-width: 768px) {
    background-size: cover;
  }
  margin-top: 65px;
  padding-bottom: 100px;
  h2 {
    font-family: "IBMPlexSans", sans-serif;
    font-size: 16px;
    line-height: 24px;
    border-bottom: 1px solid #e5e5e5;
  }
  .section--project__description {
      margin: 80px 0;
  }
  .section {
    padding: 0;
    margin: 80px 0;
  }
  .section:first-child {
    margin-top: 0;
  }
  h1.section__title {
      margin-top: 240px;
      margin-bottom: 64px;
      font-weight: 500;
      display: inline-block;
      background: rgba(12,55,245,0.15);
  }
  .container-fluid > .content {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export default Term;