import React from "react";
import Header from "./header";
import "../assets/css/layout.css";
import "../assets/css/index.css";
import "../assets/css/grid.css";
import "../assets/css/form.css";
import Footer from "./footer";

try {
    require("materialize-css");
}
catch(error) {
    console.log(error);
}

const Layout = ({ children, theme, showContact }) => (
    <>
        <Header theme={theme} showContact={showContact} />
        <main>{children}</main>
        <Footer theme={theme} showContact={showContact} />
    </>
);

Layout.defaultProps = {
    showContact: true
};

export default Layout
