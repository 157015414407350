import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { useIntl, Link } from "gatsby-plugin-intl"

import QobaltLogo from "../images/logo-only.svg";

const Header = (props) => {
    const intl = useIntl();
    const {showNav, showContact} = props;
    return(
        showNav &&
        <HeaderWrapper>
            <div className="container-fluid">
                <HeaderContainer>
                    <Link to="/">
                        <img style={{height:"40px"}} src={QobaltLogo} alt="Qobalt Technologies" />
                    </Link>
                    <QuickActions>
                        <MenuButtonWrapper>
                            <Link to="/qobalt-labs">
                                Qobalt Labs
                            </Link>
                        </MenuButtonWrapper>
                        {showContact &&
                        <Link to="/contact" className="btn btn-light animated fade">
                            {intl.formatMessage({id:"contactButton"})} <span role="img" aria-label="feeling-cool">😎</span>
                        </Link>
                        }
                    </QuickActions>
                </HeaderContainer>
            </div>
        </HeaderWrapper>
    );
};

Header.propTypes = {
    siteTitle: PropTypes.string,
};

Header.defaultProps = {
    siteTitle: ``,
    showNav: true,
    showContact: true
};

const HeaderWrapper = styled.header`
  height: 72px;
  opacity: 1;
  position: fixed;
  top:0;
  right: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  background: rgb(14,28,73);
  .container-fluid {
    max-width: 1400px;
    height: 100%;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  height: 100%;
  
`;

const QuickActions = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  a.btn {
    min-width: 100px;
    padding: 5px 20px;
    margin-left: 20px;
  }
  
`;

const MenuButtonWrapper = styled.div`
  & > a {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #ffffff;
    font-size: 16px;
  }
  & > a:hover {
    opacity: 0.95;
  }
`;

export default Header
